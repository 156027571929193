/* eslint-disable react/jsx-props-no-spreading */
import { Arrow, mergeRefs, useLayer } from "react-laag";

type Props = React.PropsWithChildren<{
  content?: React.ReactNode;
  isOpen?: boolean;
  tooltipRef?: React.LegacyRef<HTMLDivElement>;
  containerRef?: React.LegacyRef<HTMLSpanElement>;
  className?: string;
}>;

const Tooltip = ({
  children,
  content,
  tooltipRef,
  containerRef,
  className,
  isOpen = false,
}: Props) => {
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 20,
    auto: true,
  });

  return (
    <>
      <span
        {...triggerProps}
        className={className}
        ref={mergeRefs(triggerProps.ref, containerRef)}
      >
        {children}
      </span>
      {isOpen &&
        renderLayer(
          <div
            className="bg-white border shadow-md rounded-md p-6 w-[calc(100vw-20px)] max-w-[700px]"
            {...layerProps}
            ref={mergeRefs(tooltipRef, layerProps.ref)}
          >
            {content}
            <Arrow {...arrowProps} />
          </div>,
        )}
    </>
  );
};

export default Tooltip;
