export const getSuma = <T extends { count: number; price: number }>(
  items: T[],
): number =>
  items.map(({ count, price }) => count * price).reduce((p, c) => p + c, 0);

export const getCount = <T extends { count: number }>(items: T[]): number =>
  items.map(({ count }) => count).reduce((p, c) => p + c, 0);

export const getTransport = <T extends { count: number; price: number }>(
  items: T[],
): number => {
  const suma = getSuma(items);
  const count = getCount(items);
  if (suma >= SKLEP_U_ZDENY_CONFIG.freeShipingLimit) {
    return 0;
  }
  const t = SKLEP_U_ZDENY_CONFIG.transportPrices.find(
    ({ limit }) => limit === null || count <= limit,
  );
  if (t == null) {
    throw new Error("Špatně definovaný objekt slev!");
  }
  return t.price;
};

export const getTotal = <T extends { count: number; price: number }>(
  items: T[],
  freeShiping?: boolean,
) => getSuma(items) + (!freeShiping ? getTransport(items) : 0);
