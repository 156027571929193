import classNames from "classnames";

type AlertProps = React.PropsWithChildren<{
  type?: "success" | "error" | "info" | "warning";
  onClose?: () => void;
  className?: string;
}>;

const Alert = ({ type = "info", onClose, children, className }: AlertProps) => (
  <div
    className={classNames(
      {
        "flex p-4 mb-4 text-green-800 rounded-lg bg-green-50":
          type === "success",
        "flex p-4 mb-4 text-red-800 rounded-lg bg-red-50": type === "error",
        "flex p-4 mb-4 text-blue-800 rounded-lg bg-blue-50": type === "info",
        "flex p-4 mb-4 text-amber-800 rounded-lg bg-amber-50":
          type === "warning",
      },
      className,
    )}
    role="alert"
  >
    <svg
      aria-hidden="true"
      className="flex-shrink-0 w-5 h-5"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
        clipRule="evenodd"
      />
    </svg>
    <span className="sr-only">Info</span>
    <div className="ml-3 text-sm font-medium">{children}</div>
    {onClose && (
      <button
        type="button"
        className={classNames({
          "ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8":
            type === "error",
          "ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8":
            type === "success",
        })}
        aria-label="Close"
        onClick={onClose}
      >
        <span className="sr-only">Close</span>
        <svg
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    )}
  </div>
);

export default Alert;
