import { useCallback, useEffect } from "react";

const INFO_BUTTON_QUERY = "button.wine-info-button";
const CLOSE_BUTTON_QUERY = "button.wine-close-button";
const DIALOG_CLASSNAME = "wine-dialog";

const WineDetail = () => {
  const toggleHandler = useCallback((event: Event) => {
    const button = event.currentTarget;
    if (button instanceof HTMLButtonElement) {
      const id = button.dataset.info;
      if (id) {
        const dialog = document.getElementById(id);
        if (dialog) {
          if (dialog.classList.contains("hidden")) {
            dialog.classList.remove("hidden");
          } else {
            dialog.classList.add("hidden");
          }
        }
      }
    }
  }, []);

  const closeHandler = useCallback(({ target, currentTarget }: Event) => {
    const button = currentTarget;
    if (button instanceof HTMLButtonElement) {
      const id = button.dataset.info;
      if (id) {
        const dialog = document.getElementById(id);
        if (dialog) {
          dialog.classList.add("hidden");
        }
      }
    } else if (
      target instanceof HTMLElement &&
      target.classList.contains(DIALOG_CLASSNAME)
    ) {
      target.classList.add("hidden");
    }
  }, []);

  useEffect(() => {
    const buttons = document.querySelectorAll(INFO_BUTTON_QUERY);
    buttons.forEach((button) => {
      button.addEventListener("click", toggleHandler);
    });
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", toggleHandler);
      });
    };
  }, [toggleHandler]);

  useEffect(() => {
    const buttons = document.querySelectorAll(CLOSE_BUTTON_QUERY);
    buttons.forEach((button) => {
      button.addEventListener("click", closeHandler);
    });
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", closeHandler);
      });
    };
  }, [closeHandler]);

  useEffect(() => {
    const buttons = document.querySelectorAll(`.${DIALOG_CLASSNAME}`);
    buttons.forEach((button) => {
      button.addEventListener("click", closeHandler);
    });
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", closeHandler);
      });
    };
  }, [closeHandler]);

  return null;
};

export default WineDetail;
