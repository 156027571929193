import { createContext, useContext } from "react";

const currency = new Intl.NumberFormat("cs-CZ", {
  style: "currency",
  currency: "CZK",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const number = new Intl.NumberFormat("cs-CZ", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const value = {
  currency,
  number,
};

const IntlContext = createContext(value);

IntlContext.displayName = "";

export const IntlProvider = ({ children }: React.PropsWithChildren) => (
  <IntlContext.Provider value={value}>{children}</IntlContext.Provider>
);

const useIntl = () => useContext(IntlContext);

export default useIntl;
