import { createRoot } from "react-dom/client";
import "./styles.css";
import { StrictMode } from "react";
import App from "./App";

let app = document.getElementById("app");
if (!app) {
  app = document.createElement("div");
  app.setAttribute("id", "app");
  document.body.appendChild(app);
}
const root = createRoot(app);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
