import Svg from "@components/Svg";
import Tooltip from "@components/Tooltip";
import useCartStore from "@utils/cart.store";
import { Suspense, lazy, useRef, useState } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import Loading from "@components/Loading";
import CartContent from "./CartContent";

const Order = lazy(() => import("@components/Cart/Order"));

const Cart = () => {
  const [visible, setVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const items = useCartStore((state) => state.items);
  const containerRef = useRef<HTMLSpanElement>(null);

  const showHandler = () => {
    setVisible(true);
  };

  const hideHandler = () => {
    setVisible(false);
  };

  const ref = useClickAway<HTMLDivElement>(({ target }) => {
    if (
      !(
        containerRef.current &&
        target instanceof Element &&
        (containerRef.current.contains(target) ||
          containerRef.current === target)
      )
    ) {
      setIsOpen(false);
    }
  });

  const onClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    setIsOpen((o) => !o);
  };

  const onIncrementHandler = (id: number) => {
    useCartStore.getState().increment(id.toString());
  };
  const onDecrementHandler = (id: number) => {
    useCartStore.getState().decrement(id.toString());
  };

  if (Object.keys(items).length > 0) {
    return (
      <>
        <Tooltip
          tooltipRef={ref}
          containerRef={containerRef}
          isOpen={isOpen}
          className="fixed bottom-6 right-6"
          content={
            <div>
              <div className="font-bold text-lg">Košík</div>
              <hr className="my-4" />
              <CartContent
                onDecrement={onDecrementHandler}
                onIncrement={onIncrementHandler}
                items={items}
              />
              <hr className="my-4" />
              <div className="text-center">
                <button
                  className="btn-primary"
                  type="button"
                  onClick={showHandler}
                >
                  Přejít k objednávce
                </button>
              </div>
            </div>
          }
        >
          <button
            type="button"
            onClick={onClickHandler}
            className="border-2 border-black cursor-pointer bg-white rounded-full w-12 h-12 flex items-center justify-center"
          >
            <Svg
              icon="basket-shopping"
              size="24px"
              className="icon fill-black"
            />
            <div className="absolute -top-1 -left-1 bg-primary flex justify-center items-center w-6 h-6 text-white rounded-full">
              {Object.values(items).reduce((p, c) => p + c, 0)}
            </div>
          </button>
        </Tooltip>
        {visible && (
          <Suspense fallback={<Loading />}>
            <Order visible onClose={hideHandler} />
          </Suspense>
        )}
      </>
    );
  }
  return null;
};

export default Cart;
