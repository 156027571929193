import Svg from "@components/Svg";
import { useMemo } from "react";
import Alert from "@components/Alert";
import useIntl from "src/services/IntlService";
import { getSuma, getTotal, getTransport } from "./Card.helpers";

type Props = {
  items: Record<string, number>;
  onIncrement?: (id: number) => void;
  onDecrement?: (id: number) => void;
  showOverview?: boolean;
  freeShiping?: boolean;
};

type ItemType<T> = {
  count: number;
} & T;

const CartContent = ({
  items,
  onDecrement,
  onIncrement,
  showOverview,
  freeShiping,
}: Props) => {
  const intl = useIntl();
  const wines = useMemo(() => {
    const result: ItemType<WinePostType>[] = [];
    SKLEP_U_ZDENY_CONFIG.wines.forEach((w) => {
      const count = items[w.id.toString()];
      if (count > 0) {
        result.push({ ...w, count });
      }
    });
    return result;
  }, [items]);

  const onDecrementHandler = (id: number) => () => {
    onDecrement?.(id);
  };
  const onIncrementHandler = (id: number) => () => {
    onIncrement?.(id);
  };

  const suma = getSuma(wines);
  const transport = !freeShiping ? getTransport(wines) : 0;
  const total = getTotal(wines, freeShiping);

  if (wines.length > 0) {
    return (
      <>
        {(() => {
          if (!freeShiping) {
            if (transport !== 0) {
              return (
                <Alert type="warning">
                  Nakupte ještě za{" "}
                  <strong>
                    {intl.currency.format(
                      SKLEP_U_ZDENY_CONFIG.freeShipingLimit - suma,
                    )}
                  </strong>{" "}
                  a máte dopravu zdarma! Doprava je počítána po šesti lahvích.
                </Alert>
              );
            }
            return <Alert type="success">Máte dopravu zdarma!</Alert>;
          }
          return undefined;
        })()}
        <div className="font-bold">Vína</div>
        <div className="overflow-x-auto">
          <table className="pl-2 w-full">
            <tbody>
              {wines.map(({ id, title, count, year, price, attribute }) => (
                <tr key={id}>
                  <td className="pr-2">{title}</td>
                  <td className="px-2 hidden sm:!table-cell">{attribute}</td>
                  <td className="px-2 text-right">{year}</td>
                  <td aria-label="Odstranit" className="px-2">
                    <div className="flex gap-1 items-center">
                      <Svg
                        icon="minus"
                        onClick={onDecrementHandler(id)}
                        className="h-4 w-4 p-0.5 rounded-full bg-primary fill-white hover:bg-primaryHover cursor-pointer"
                      />
                      <div className="w-5 text-center">{count}</div>
                      <Svg
                        icon="plus"
                        onClick={onIncrementHandler(id)}
                        className="h-4 w-4 p-0.5 rounded-full bg-primary fill-white hover:bg-primaryHover cursor-pointer"
                      />
                    </div>
                  </td>
                  <td className="px-2 text-right whitespace-nowrap hidden sm:!table-cell">
                    {count >= 1 ? `${intl.number.format(price)} Kč / ks` : ""}
                  </td>
                  <td className="pl-2 font-bold text-right">
                    {intl.currency.format(count * price)}
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan={2}
                  className="hidden sm:!table-cell"
                  aria-label="space"
                />
                <td colSpan={3} className="text-right pr-2">
                  Celkem:
                </td>
                <td className="text-right pl-2">
                  {intl.currency.format(suma)}
                </td>
              </tr>
              {showOverview && (
                <>
                  <tr>
                    <td
                      colSpan={2}
                      className="hidden sm:!table-cell"
                      aria-label="space"
                    />
                    <td colSpan={3} className="text-right pr-2">
                      Doprava:
                    </td>
                    <td className="text-right pl-2">
                      {transport === 0
                        ? "Zdarma"
                        : intl.currency.format(transport)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      className="hidden sm:!table-cell"
                      aria-label="space"
                    />
                    <td colSpan={3} className="text-right font-bold pr-2">
                      K úhradě:
                    </td>
                    <td className="text-right pl-2 font-bold">
                      {intl.currency.format(total)}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
  return null;
};

export default CartContent;
