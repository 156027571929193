import { lazy, useState, Suspense } from "react";
import ReplaceElement from "./ReplaceElement";
import ReservationButton from "./ReservationButton";
import Loading from "./Loading";

const TastingForm = lazy(() => import("@components/TastingForm"));
const TASTING_BUTTON_QUERY = "button.add-tasting";

const TasteReservation = () => {
  const [tasting, setTasting] = useState<string>();
  const onClose = () => {
    setTasting(undefined);
  };
  return (
    <>
      <ReplaceElement
        querySelector={TASTING_BUTTON_QUERY}
        component={ReservationButton}
        onAction={setTasting}
      />
      {tasting && (
        <Suspense fallback={<Loading />}>
          <TastingForm id={tasting} onClose={onClose} />
        </Suspense>
      )}
    </>
  );
};

export default TasteReservation;
