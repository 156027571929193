import Svg from "@components/Svg";

type Props = {
  id: string;
  onAction: (id: string) => void;
};

const ReservationButton = ({ id, onAction }: Props) => {
  const onClick = () => onAction(id);
  return (
    <button
      type="button"
      className="btn-primary self-center add-accommodations"
      data-id={id}
      onClick={onClick}
    >
      <Svg
        icon="calendar"
        size="20px"
        className="icon inline-block fill-white mr-2"
      />
      Rezervovat
    </button>
  );
};

export default ReservationButton;
