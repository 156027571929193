import { lazy, useState, Suspense } from "react";
import ReplaceElement from "./ReplaceElement";
import ReservationButton from "./ReservationButton";
import Loading from "./Loading";

const AccommodationsForm = lazy(() => import("@components/AccommodationsForm"));
const ACCOMMODATIONS_BUTTON_QUERY = "button.add-accommodations";

const AccommodationsReservation = () => {
  const [accomodation, setAccomodation] = useState<string>();
  const onClose = () => {
    setAccomodation(undefined);
  };
  return (
    <>
      <ReplaceElement
        querySelector={ACCOMMODATIONS_BUTTON_QUERY}
        component={ReservationButton}
        onAction={setAccomodation}
      />
      {accomodation && (
        <Suspense fallback={<Loading />}>
          <AccommodationsForm id={accomodation} onClose={onClose} />
        </Suspense>
      )}
    </>
  );
};

export default AccommodationsReservation;
