import { create } from "zustand";
import omit from "lodash-es/omit";

type Cart = {
  items: Record<string, number>;
  removeItem: (id: string) => void;
  increment: (id: string) => void;
  decrement: (id: string) => void;
  clear: () => void;
};

const useCartStore = create<Cart>((set) => ({
  items: {},
  clear: () => set(() => ({ items: {} })),
  removeItem: (id) => set((state) => ({ items: omit(state.items, [id]) })),
  increment: (id) =>
    set((state) => {
      if (state.items[id] != null) {
        return { items: { ...state.items, [id]: state.items[id] + 1 } };
      }
      return { items: { ...state.items, [id]: 1 } };
    }),
  decrement: (id) =>
    set((state) => {
      if (state.items[id] <= 1) {
        const itemsCopy = { ...state.items };
        delete itemsCopy[id];
        return { items: itemsCopy };
      }
      return { items: { ...state.items, [id]: state.items[id] - 1 } };
    }),
}));

export default useCartStore;
