import Svg from "@components/Svg";

type Props = {
  decrement?: () => void;
  increment?: () => void;
  value?: number;
  min?: number;
  max?: number;
};

const NumberClicker = ({
  decrement,
  increment,
  value = 0,
  min,
  max,
}: Props) => {
  const incrementHandler = () => {
    if (max == null || value < max) {
      increment?.();
    }
  };
  const decrementHandler = () => {
    if (min == null || value > min) {
      decrement?.();
    }
  };

  return (
    <div className="inline-flex gap-2 items-center">
      <button
        disabled={min != null && value <= min}
        onClick={decrementHandler}
        type="button"
        className="btn-primary !p-0 w-8 h-8 inline-flex items-center justify-center"
        aria-label="Minus"
      >
        <Svg icon="minus" size="20px" className="icon fill-white" />
      </button>
      <span className="font-bold">{value}</span>
      <button
        disabled={max != null && value >= max}
        onClick={incrementHandler}
        type="button"
        className="btn-primary !p-0 w-8 h-8 inline-flex items-center justify-center"
        aria-label="Plus"
      >
        <Svg icon="plus" size="20px" className="icon fill-white" />
      </button>
    </div>
  );
};

export default NumberClicker;
