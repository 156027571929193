import { useRef, useEffect, useCallback } from "react";
import SimpleLightbox from "simplelightbox";
import "simplelightbox/dist/simple-lightbox.min.css";

const BUTTON_GALLERY_QUERY = ".suz-gallery-button";

const getGalleryId = (button: HTMLButtonElement) => button.dataset.galleryid;

const Gallery = () => {
  const lightboxRef = useRef<Record<string, SimpleLightbox>>({});

  const openHandler = useCallback(({ currentTarget }: Event) => {
    if (currentTarget instanceof HTMLButtonElement) {
      const id = getGalleryId(currentTarget);
      if (id) {
        lightboxRef.current[id]?.open();
      }
    }
  }, []);

  useEffect(() => {
    const buttons = document.querySelectorAll(BUTTON_GALLERY_QUERY);
    buttons.forEach((button) => {
      if (button instanceof HTMLButtonElement) {
        button.addEventListener("click", openHandler);
        const id = getGalleryId(button);
        if (id) {
          lightboxRef.current[id] = new SimpleLightbox(`#${id} a`);
        }
      }
    });
    return () => {
      buttons.forEach((button) => {
        if (button instanceof HTMLButtonElement) {
          button.removeEventListener("click", openHandler);
        }
      });
      lightboxRef.current = {};
    };
  }, [openHandler]);
  return null;
};

export default Gallery;
