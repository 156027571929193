import Icons from "../../../assets/icons.svg";

type Props = {
  color?: string;
  size?: string;
  icon: string;
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
};

const Svg = ({ color, size, icon, className, onClick }: Props) => (
  <svg
    fill={color}
    width={size}
    height={size}
    className={className}
    onClick={onClick}
  >
    <use href={`${Icons}#${icon}`} />
  </svg>
);

export default Svg;
