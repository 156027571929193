import AccommodationsReservation from "@components/AccommodationsReservation";
import Cart from "@components/Cart";
import CartButton from "@components/CartButton";
import Gallery from "@components/Gallery";
import ReplaceElement from "@components/ReplaceElement";
import TasteReservation from "@components/TasteReservation";
import WineDetail from "@components/WineDetail";
import { IntlProvider } from "./services/IntlService";

const WINE_BUTTON_QUERY = "button.add-wine";

const App = () => (
  <IntlProvider>
    <WineDetail />
    <Gallery />
    <AccommodationsReservation />
    <TasteReservation />
    <ReplaceElement querySelector={WINE_BUTTON_QUERY} component={CartButton} />
    <Cart />
  </IntlProvider>
);

export default App;
