import NumberClicker from "@components/NumberClicker";
import Svg from "@components/Svg";
import useCartStore from "@utils/cart.store";

type Props = {
  id: string;
};

const CartButton = ({ id }: Props) => {
  const count = useCartStore<number | undefined>((state) => state.items[id]);
  const increment = () => {
    useCartStore.getState().increment(id);
  };
  const decrement = () => {
    useCartStore.getState().decrement(id);
  };
  if (count != null && count > 0) {
    return (
      <NumberClicker
        decrement={decrement}
        increment={increment}
        value={count}
      />
    );
  }
  return (
    <button
      onClick={increment}
      type="button"
      className="btn-primary add-wine"
      data-id={id}
    >
      <Svg
        icon="basket-shopping"
        size="20px"
        className="icon inline-block fill-white mr-2"
      />
      Do košíku
    </button>
  );
};

export default CartButton;
