import React, { FunctionComponent, useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";

type Props<T> = {
  component: FunctionComponent<T>;
  querySelector: string;
  onAction?: (id: string) => void;
};

const getElementsMap = (
  buttons: NodeListOf<HTMLButtonElement>,
): Record<string, HTMLElement> => {
  const elements: Record<string, HTMLElement> = {};
  buttons.forEach((button) => {
    const parent = button.parentElement;
    const { id } = button.dataset;
    if (id && parent) {
      elements[id] = parent;
    }
  });
  return elements;
};

const ReplaceElement = <
  T extends { id: string; onAction?: (id: string) => void },
>({
  querySelector,
  component,
  onAction,
}: Props<T>): JSX.Element => {
  const buttons = useRef(
    document.querySelectorAll<HTMLButtonElement>(querySelector),
  );
  const parentRefs = useRef(getElementsMap(buttons.current));

  useLayoutEffect(() => {
    buttons.current.forEach((button) => {
      if (button instanceof HTMLElement) {
        button.remove();
      }
    });
  }, [querySelector]);

  return (
    <>
      {Object.entries(parentRefs.current).map(([id, parent]) =>
        createPortal(
          React.createElement(component, { id, onAction } as T),
          parent,
          id,
        ),
      )}
    </>
  );
};

export default ReplaceElement;
